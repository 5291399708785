.resumeGrid {
    width: 85vw;
    margin: 0 auto;
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
    gap: 20px;
}

.resumeColumns {
 display: flex;
 align-items:center;
 flex-direction: column;
}

.mainHeading {
    font-family: 'Teko', sans-serif;
    font-size: 2rem;
    color: #F2E9E4
}

.linkHeading {
    font-family: 'Teko', sans-serif;
    font-size: 2rem;
    color: #F2E9E4
}

.linkHeading:hover {
    color: #C9ADA7;
}

.subHeading {
    font-family: 'Teko', sans-serif;
    font-size: 1.5rem;
    color: #F2E9E4
}

.readText {
    font-family: 'Cormorant', serif;
    font-size: 1rem;
    color: #F2E9E4;
}

.subText {
    font-family: 'Cormorant', serif;
    font-size: .9rem;
    color:#F2E9E4;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 70px;
}

.buttonBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #9A8C98;
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 30px;
    box-shadow: 5px .3rem .3rem rgba(201, 173, 167, 0.5) !important;
}

@media (min-width:640px) {}

@media (min-width:1024px){
    .resumeGrid {
        width: 85vw;
        margin: 0 auto;
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(40vw, 1fr));
        gap: 20px;
    }
    .resumeColumns:first-of-type
    {
        border-right: 2px solid #C9ADA7;
        padding-right: 10px;
    }
}

@media (min-width:1700px)
{
    .resumeBlock
    {
        width: 80%;
        align-items: flex-start;
    }
}