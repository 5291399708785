.profileText {
  color: #F2E9E4;
  font-family: 'Cormorant', serif;
  font-size: 1.5em;
  padding-left: 20px;
  padding-right: 20px;
}

.profileImage {
  border-radius: 10%;
  object-fit: contain;
  max-width: 70%;
  max-height: 70%;
  width: auto;
  height: auto;
  border: 2px solid #F2E9E4;
}

.profileCover {
  height: 100vh;
  width: 98vw;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.contactGrid {
  width: 85vw;
  margin: 0 auto;
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40vw, 1fr));
  gap: 20px;
}

.profileImageBG
{
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactImage {
  width: 64px;
  margin: 10px;
}

@media (min-width:640px) {}

@media (min-width:1024px) {
  .profileImage
  {
    max-width: 50%;
    max-height: 50%;
  }
  .profileText
  {
    font-size: 2em;
    padding: 20px;
  }
  .profileCover {
    flex-direction: row;
  }
  .profileImageBG {
    flex-grow: 1;
    width: 40vw;
  }
}