.cardContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid #9A8C98;
    border-radius: 10px;
    padding: 25px;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transform: translate(0);
    transition: transform 0.25s ease-out;
}

.cardContainer:hover {
    transform: scale(1.05);
    border: 2px solid #9A8C98;
    box-shadow: 0 0 1.1rem rgba(201, 173, 167, 0.5) !important;
}

.centering {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardImage {
    width: 85%;
    margin-bottom: 10px;
    border: 2px solid #F2E9E4;
    border-radius: 5%;
}

.lightText {
    color: #F2E9E4;
}

.lightText:hover {
    color: #C9ADA7;
}

.heading {
    font-family: 'Teko', sans-serif;
}

.readingText {
    font-family: 'Cormorant', serif;
    font-size: 1.2em;
}