.navHead {
  background-color: #22223B;
  padding: 20px;
}

.foot {
  background-color: #22223B;
  width: 100%;
  border-top: 1px solid #9A8C98;
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.footChild:first-child {
  flex-grow: 1;
}

.footChild:nth-child(2) {
  flex-grow: 1;
}

.footChild:last-child {
  flex-grow: 0;
}

.footerImage {
  height: 32px;
  width: 36px;
}

.medPadding {
  padding: 5px;
}

.navBrand {
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navBrand:hover {
  transform: scale(1.15);
}

.navLink {
  color: #F2E9E4 !important;
  font-family: 'Raleway', sans-serif;
  display: block;
  padding: .5rem 1rem;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translate(0);
  transition: transform 0.1s ease-out;
}

.navLink:hover {
  color: #C9ADA7 !important;
}

.footerText {
  color: lightgrey;
  font-family: 'Raleway', sans-serif;
  margin: 0;
}

.pageHead {
  font-family: 'Teko', sans-serif;
  font-size: 2.5em;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #F2E9E4;
}

.logo
{
  height: 60px;
}

.nameLogo
{
  height:30px;
}

@media (min-width:640px) {
  .navLink {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width:1024px) {
  .navLink {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navLink:hover {
    transform: scale(1.15);
  }
  .logo
  {
    height:70px;
  }
  .nameLogo
  {
    height: 50px;
  }
}