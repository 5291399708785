.descriptionText {
    font-family: 'Cormorant', serif;
    font-size: 1.5em;
    color: #F2E9E4;
    width: 80vw;
}

.iconImage {
    border-radius: 10%;
    object-fit: contain;
    max-width: 70%;
    max-height: 70%;
    width: auto;
    height: auto;
    border: 2px solid #F2E9E4;
}

.descriptionContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 98vw;
}

.descriptionContainer:nth-of-type(even) {
    flex-direction: column;
}

.descriptionTextContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.descriptionImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.topDescriptionBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.techContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #9A8C98;
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 30px;
}

.techText {
    font-family: 'Cormorant', serif;
    font-size: 1.2em;
    color: #F2E9E4;
}

.techHead {
    font-family: 'Teko', sans-serif;
    font-size: 1.8em;
    color: #F2E9E4;
    margin: 20px;
}

.videoFrame {
    height: 200px;
    width: 355px;
}

@media (min-width:640px) {
    .descriptionText {
        width: 75vw;
    }
    .videoFrame {
        width: 560px;
        height: 315px;
    }
}

@media (min-width:1024px) {
    .descriptionTextContainer {
        width: 40vw;
    }
    .descriptionContainer {
        flex-direction: row;
        justify-content: space-around;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .descriptionContainer:nth-of-type(even) {
        flex-direction: row;
    }
    .descriptionText {
        width: 50vw;
    }
    .descriptionImageContainer {
        display: flex;
        width: 40vw;
    }
    .descriptionImageContainer:nth-of-type(even) {
        justify-content: flex-end;
    }
    .descriptionImageContainer:nth-of-type(odd) {
        justify-content: flex-start;
    }
}