.cardGrid {
    width: 85vw;
    margin: 0 auto;
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

@media (min-width:640px) {}

@media (min-width:1024px) {}